import { Box, Typography } from '@mui/material'
import { Dayjs } from 'dayjs'
import linkifyHtml from 'linkify-html'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { notificationApi } from 'hooks/api/notification'
import { notificationStatus, Notification } from 'types/notification'

const formatDateWithDay = (date: Dayjs) => {
  return date.format('YYYY年MM月DD日(ddd) HH:mm')
}

export default function AnnouncementPage() {
  const { id } = useParams<{ id: string }>()
  const [announcement, setAnnouncement] = useState<Notification>()
  const fetchData = useCallback(() => {
    notificationApi()
      .findById(id!)
      .then((res) => {
        setAnnouncement(res.data)
        if (res.data.status === notificationStatus.Notified) {
          notificationApi().readed(id!)
        }
      })
  }, [id])

  const convertToLink = (text: string) => {
    const options = {
      defaultProtocol: 'http',
      attributes: {
        style: 'color: #00f; text-decoration: underline;',
      },
      formatHref: (href: string) => {
        const url = new URL(href, 'http://straya.com')
        url.searchParams.append('slide', 'right')
        return url.href.replace('http://straya.com', '')
      },
    }
    return linkifyHtml(text, options)
  }

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <Box
      sx={{
        padding: '16px',
        maxWidth: '600px',
        margin: '0 auto',
        backgroundColor: '#fff',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '16px',
        }}
      >
        <Box
          sx={{
            display: 'inline-block',
            padding: '4px 8px',
            backgroundColor: '#D3D3D3',
            borderRadius: '4px',
            fontWeight: 'bold',
            color: '#fff',
            fontSize: '14px',
            textAlign: 'center',
            marginRight: '8px',
          }}
        >
          告知
        </Box>

        <Typography
          variant="body2"
          sx={{
            color: '#666',
            fontSize: '14px',
            marginRight: 'auto',
          }}
        >
          {announcement?.sentAt && formatDateWithDay(announcement.sentAt)}
        </Typography>
      </Box>
      <Typography
        variant="h4"
        sx={{
          fontWeight: 'bold',
          marginBottom: '16px',
          borderBottom: '1px solid #A0A0A0',
          padding: 2,
        }}
      >
        {announcement?.title}
      </Typography>

      <Typography
        variant="h5"
        sx={{ whiteSpace: 'pre-line', marginBottom: '16px', padding: 2 }}
        dangerouslySetInnerHTML={{
          __html: convertToLink(announcement?.content || ''),
        }}
      ></Typography>
    </Box>
  )
}
