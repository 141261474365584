import { AxiosResponse } from 'axios'

import { _axios } from 'hooks/axios'
import { Assign } from 'types/assign'

const UPDATE_SECURITY_NOTE = (id: string) => `/assigns/${id}/security-note`
const FIND_BY_ID = (id: string) => `/assigns/${id}`
const UPDATE_STATUS = (id: string) => `/assigns/${id}`
const UPDATE_SECURITY_REVIEW = (id: string) => `/assigns/${id}/review`
const UPDATE_SECURITY_REVIEW_REASONE = (id: string) =>
  `/assigns/${id}/review/reasone`

export const assignApi = () => {
  const findById = async (id: string): Promise<AxiosResponse<Assign>> => {
    return await _axios()
      .get(FIND_BY_ID(id))
      .then((res) => {
        res.data = res.data.assign
        return res
      })
  }

  const updateStatus = async (
    id: string,
    status: number,
  ): Promise<AxiosResponse<string>> => {
    const params = {
      status: status,
    }
    return await _axios()
      .put(UPDATE_STATUS(id), params)
      .then((res) => {
        res.data = res.data
        return res
      })
  }

  const updateSecurityNote = async (
    id: string,
    note: string,
  ): Promise<AxiosResponse<string>> => {
    const params = {
      securityNote: note,
    }
    return await _axios()
      .put(UPDATE_SECURITY_NOTE(id), params)
      .then((res) => {
        res.data = res.data
        return res
      })
  }

  const updateSecurityReview = async (
    id: string,
    review: number,
  ): Promise<AxiosResponse<string>> => {
    const params = {
      review: review,
    }
    return await _axios()
      .put(UPDATE_SECURITY_REVIEW(id), params)
      .then((res) => {
        res.data = res.data
        return res
      })
  }

  const updateSecurityReviewReasone = async (
    id: string,
    reasone: string,
  ): Promise<AxiosResponse<string>> => {
    const params = {
      reasone: reasone,
    }
    return await _axios()
      .put(UPDATE_SECURITY_REVIEW_REASONE(id), params)
      .then((res) => {
        res.data = res.data
        return res
      })
  }

  return {
    findById,
    updateStatus,
    updateSecurityNote,
    updateSecurityReview,
    updateSecurityReviewReasone,
  }
}
