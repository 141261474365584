import { Breakpoint, Dialog, DialogContent } from '@mui/material'
import { CSSProperties, ReactNode } from 'react'

interface Props {
  children: ReactNode
  open: boolean
  maxWidth?: Breakpoint
  height?: string
  minWidth?: string
  handleClose?: () => void
  className?: string
  style?: CSSProperties
}

export default function KumocanDialog(props: Props) {
  const {
    children,
    open,
    maxWidth = 'md',
    height = 'auto',
    minWidth = 'auto',
    handleClose,
    className,
    style: style = {},
  } = props

  return (
    <Dialog
      scroll="paper"
      open={open}
      onClose={handleClose}
      maxWidth={maxWidth}
      className={className}
      sx={style}
      PaperProps={{
        style: {
          overflowY: 'auto',
          minHeight: height,
          minWidth: minWidth,
        },
      }}
    >
      <DialogContent>{children}</DialogContent>
    </Dialog>
  )
}
