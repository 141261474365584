import {
  format,
  addMonths,
  subMonths,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  eachDayOfInterval,
  isSameMonth,
} from 'date-fns'
import React, { useState, CSSProperties } from 'react'

export type Schedule = {
  date: Date
  color: string
  icon?: React.ReactNode
  dayShiftTask?: Task
  nightShiftTask?: Task
  dayOrNightShiftTask?: Task
  trainingShiftTask?: Task
  dayAndNightShiftTask?: Task
  reviewShiftTask?: Task
}

export type Task = {
  assignId: string
  time: string
  name: string
  color: string
  icon?: React.ReactNode
  status: number
}

export type CalendarProps = {
  date: Date
  schedules: Schedule[]
  onClick: (schedule: Schedule | undefined) => void
  changeMonth: (date: Date) => void
}

export const Calendar: React.FC<CalendarProps> = ({
  date,
  schedules,
  onClick,
  changeMonth,
}) => {
  const [currentDate, setCurrentDate] = useState(date)

  const nextMonth = () => {
    const month = addMonths(currentDate, 1)
    setCurrentDate(month)
    changeMonth(month)
  }
  const prevMonth = () => {
    const month = subMonths(currentDate, 1)
    setCurrentDate(month)
    changeMonth(month)
  }

  const getDaysInMonth = () => {
    const start = startOfWeek(startOfMonth(currentDate), { weekStartsOn: 0 })
    const end = endOfWeek(endOfMonth(currentDate), { weekStartsOn: 0 })
    return eachDayOfInterval({ start, end })
  }

  const isToday = (date: Date) => {
    const today = new Date()
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    )
  }

  const isFuture = (date: Date) => {
    const today = new Date()
    return today < date || isToday(date)
  }

  const containerStyle: CSSProperties = {
    width: '100%',
    maxWidth: '400px',
    margin: '0 auto',
    backgroundColor: 'white',
    overflow: 'hidden',
    fontFamily: 'Arial, sans-serif',
  }

  const headerStyle: CSSProperties = {
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px 24px',
  }

  const buttonStyle: CSSProperties = {
    background: 'none',
    border: 'none',
    fontSize: '18px',
    color: '#4b5563',
    cursor: 'pointer',
  }

  const titleStyle: CSSProperties = {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#1f2937',
  }

  const gridStyle: CSSProperties = {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    backgroundColor: '#e5e7eb', // Light gray background for gap
  }

  const DayName: React.FC<{ day: string; index: number }> = ({
    day,
    index,
  }) => {
    let color = '#6b7280'
    if (day === '土') {
      color = '#2563eb'
    } else if (day === '日') {
      color = '#d9463d'
    }

    const dayNameStyle: CSSProperties = {
      textAlign: 'center',
      fontSize: '12px',
      fontWeight: 'bold',
      color: color,
      padding: '8px 0',
      backgroundColor: 'white',
      ...(index > 0 ? { borderLeft: '0.5px dotted #d1d5db' } : {}),
    }

    return <div style={dayNameStyle}>{day}</div>
  }

  const dayStyle = (
    isCurrentMonth: boolean,
    date: Date,
    index: number,
  ): CSSProperties => {
    const shouldHaveBorderLeft = ![0, 7, 14, 21, 28].includes(index)
    // 過去の日付は薄いグレーの背景色
    let backgroundColor = '#e5e7eb'
    if (isToday(date)) {
      // 今日の日付は黄色の背景色
      backgroundColor = '#fef3c7'
    } else if (isFuture(date)) {
      // 未来の日付は白の背景色
      backgroundColor = 'white'
    }

    return {
      padding: '2px',
      textAlign: 'center',
      fontSize: '8px',
      color: isToday(date) ? 'white' : isCurrentMonth ? '#1f2937' : '#d1d5db',
      backgroundColor: backgroundColor,
      borderTop: '0.5px dotted #d1d5db',
      ...(shouldHaveBorderLeft ? { borderLeft: '0.5px dotted #d1d5db' } : {}),
      width: 'calc(100% - 4px)',
      height: 'calc(80px - 4px)',
    }
  }

  const todayStyle: CSSProperties = {
    width: '18px',
    height: '18px',
    borderRadius: '50%',
    backgroundColor: 'black',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }

  const schedulesStyle = (date: Date): CSSProperties => {
    return {
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: '2px',
      padding: '2px',
      opacity: isFuture(date) ? 1 : 0.5,
    }
  }

  const scheduleStyle: CSSProperties = {
    height: '24px',
    width: '100%',
  }

  const taskStyle = (isFuture: boolean, task: Task): CSSProperties => {
    const color = isFuture ? task.color : '#d1d5db'
    const borderColor = task.status === 20 ? 'red' : color
    return {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 'calc(100% - 4px)',
      height: 'calc(22px - 4px)',
      borderRadius: '2px',
      backgroundColor: color,
      border: `2px solid ${borderColor}`,
    }
  }

  return (
    <div style={containerStyle}>
      <div style={headerStyle}>
        <button onClick={prevMonth} style={buttonStyle}>
          &lt;
        </button>
        <h2 style={titleStyle}>
          <span style={{ fontSize: '14px' }}>
            {format(currentDate, 'yyyy年')}
          </span>{' '}
          {format(currentDate, 'M月')}
        </h2>
        <button onClick={nextMonth} style={buttonStyle}>
          &gt;
        </button>
      </div>
      <div style={gridStyle}>
        {['日', '月', '火', '水', '木', '金', '土'].map((day, index) => (
          <DayName key={day} day={day} index={index} />
        ))}
        {getDaysInMonth().map((date, index) => (
          <div
            key={date.toString()}
            style={dayStyle(isSameMonth(date, currentDate), date, index)}
            onClick={() => {
              const schedule = schedules.find(
                (schedule) => schedule.date.getDate() === date.getDate(),
              )
              onClick(schedule)
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '18px',
              }}
            >
              {isToday(date) ? (
                <p style={todayStyle}>{format(date, 'd')}</p>
              ) : (
                format(date, 'd')
              )}
            </div>

            {schedules.map((schedule) => {
              if (
                isSameMonth(schedule.date, date) &&
                schedule.date.getDate() === date.getDate()
              ) {
                return (
                  <div
                    key={schedule.date.getDate()}
                    style={schedulesStyle(date)}
                  >
                    {schedule.dayShiftTask && (
                      <div style={scheduleStyle}>
                        <div
                          key={schedule.dayShiftTask.name}
                          style={taskStyle(
                            isFuture(date),
                            schedule.dayShiftTask,
                          )}
                        >
                          {schedule.dayShiftTask.icon}
                        </div>
                      </div>
                    )}

                    {schedule.nightShiftTask && (
                      <div style={scheduleStyle}>
                        <div
                          key={schedule.nightShiftTask.name}
                          style={taskStyle(
                            isFuture(date),
                            schedule.nightShiftTask,
                          )}
                        >
                          {schedule.nightShiftTask.icon}
                        </div>
                      </div>
                    )}

                    {schedule.dayOrNightShiftTask && (
                      <div style={scheduleStyle}>
                        <div
                          key={schedule.dayOrNightShiftTask.name}
                          style={taskStyle(
                            isFuture(date),
                            schedule.dayOrNightShiftTask,
                          )}
                        >
                          {schedule.dayOrNightShiftTask.icon}
                        </div>
                      </div>
                    )}

                    {schedule.trainingShiftTask && (
                      <div style={scheduleStyle}>
                        <div
                          key={schedule.trainingShiftTask.name}
                          style={taskStyle(
                            isFuture(date),
                            schedule.trainingShiftTask,
                          )}
                        >
                          {schedule.trainingShiftTask.icon}
                        </div>
                      </div>
                    )}

                    {schedule.dayAndNightShiftTask && (
                      <div style={scheduleStyle}>
                        <div
                          key={schedule.dayAndNightShiftTask.name}
                          style={taskStyle(
                            isFuture(date),
                            schedule.dayAndNightShiftTask,
                          )}
                        >
                          {schedule.dayAndNightShiftTask.icon}
                        </div>
                      </div>
                    )}

                    {schedule.reviewShiftTask && (
                      <div style={scheduleStyle}>
                        <div
                          key={schedule.reviewShiftTask.name}
                          style={taskStyle(
                            isFuture(date),
                            schedule.reviewShiftTask,
                          )}
                        >
                          {schedule.reviewShiftTask.icon}
                        </div>
                      </div>
                    )}
                  </div>
                )
              } else {
                return null
              }
            })}
          </div>
        ))}
      </div>
    </div>
  )
}
