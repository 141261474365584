import { Calendar, CalendarProps, Schedule, Task } from '@kansei/ui'
import NightlightIcon from '@mui/icons-material/Nightlight'
import WbSunnyIcon from '@mui/icons-material/WbSunny'
import {
  Box,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
} from '@mui/material'
import dayjs from 'dayjs'
import React, {
  useEffect,
  CSSProperties,
  useState,
  ChangeEvent,
  cloneElement,
} from 'react'

import KumocanButton from 'components/button'
import KumocanDialog from 'components/dialog'
import { notificationApi } from 'hooks/api/notification'
import { shiftApi } from 'hooks/api/shit'
import { NewDate, isFuture } from 'hooks/helper/date'
import { ASSIGN_STATUS, AssignWithOrder } from 'types/assign'
import { OrderWithContractDetail } from 'types/order'
import { Shift } from 'types/shift'

import ListCalendar, { ListCalendarProps } from './ListCalendar'
import TabComponent from './TabComponent'

const scheduleStyle: CSSProperties = {
  padding: '8px',
}

const tasksStyle: CSSProperties = {
  marginTop: '8px',
  display: 'flex',
  flexFlow: 'column',
  gap: '8px',
}

const taskStyle = (isFuture: boolean, task: Task): CSSProperties => {
  const color = isFuture ? task.color : '#d1d5db'
  const borderColor = task.status === 20 ? 'red' : color
  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    borderRadius: '8px',
    backgroundColor: color,
    padding: '8px',
    border: `2px solid ${borderColor}`,
    color: 'white',
  }
}

const flatRowStyle: CSSProperties = {
  paddingBottom: '10px',
  marginBottom: '16px',
  fontSize: '14px',
  fontWeight: 'bold',
  borderBottom: '2px dotted #eee',
}

const rowStyle: CSSProperties = {
  display: 'flex',
  gap: '8px',
  marginBottom: '16px',
  borderBottom: '2px dotted #eee',
  paddingBottom: '10px',
}

const labelStyle: CSSProperties = {
  width: '28%',
  fontWeight: 'bold',
  color: '#555',
  fontSize: '14px',
}

const contentStyle: CSSProperties = {
  flex: 1,
}

const multiContentStyle: CSSProperties = {
  display: 'flex',
  flexWrap: 'wrap',
  width: '75%',
  gap: '8px',
}

const tagStyle: CSSProperties = {
  backgroundColor: '#e0e0e0',
  padding: '5px 10px',
  borderRadius: '4px',
  fontSize: '14px',
}

const submitButtonStyle: CSSProperties = {
  height: '50px',
  fontSize: '18px',
  borderRadius: '4px',
}

export default function ShiftPage(): JSX.Element {
  const [selectCalendar, setSelectCalendar] = useState<boolean>(false)
  const [currentDate, setCurrentDate] = useState<Date | undefined>(undefined)
  const [schedule, setSchedule] = useState<Schedule | undefined>(undefined)
  const [assign, setAssign] = useState<AssignWithOrder | undefined>(undefined)
  const [open, setOpen] = useState<boolean>(false)
  const [res, setRes] = useState<number>(0)
  const [replyWhenUnavailable, setReplyWhenUnavailable] = useState<string>('')
  const [replyWhenUnavailableErr, setReplyWhenUnavailableErr] =
    useState<string>('')
  const [replyFormat, setReplyFormat] = useState<string>()
  const [replyWhenAvailable, setReplyWhenAvailable] = useState<string>('')
  const [shifts, setShifts] = useState<Shift[]>([])

  const fetchData = () => {
    // 現在日の月の初めの日付と最後の日付を取得 (Dayjsを使用)
    const start = dayjs().startOf('month')
    const end = dayjs().endOf('month')

    shiftApi()
      .findByDate(start, end)
      .then((res) => {
        setShifts(res.data)
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const scheduleColor = (schedule: number): string => {
    switch (schedule) {
      case 1:
        return 'rgb(0, 0, 0, 0.1)'
      case 2:
        return 'rgb(255, 72, 0, 0.5)'
      case 3:
        return 'rgb(0, 98, 140, 0.5)'
      case 4:
        return 'rgb(223, 173, 104, 0.5)'
      case 5:
        return 'rgb(137, 211, 188, 0.5)'
      default:
        return 'white'
    }
  }

  const calendarIconStyle: CSSProperties = {
    display: 'block',
    fontSize: '12px',
    color: 'rgb(255, 255, 255, 0.5)',
  }

  const scheduleIcon = (schedule: number): React.ReactNode | undefined => {
    if (schedule === 2) {
      return <WbSunnyIcon style={calendarIconStyle} />
    } else if (schedule === 3) {
      return <NightlightIcon style={calendarIconStyle} />
    }
    return undefined
  }

  const schedules = shifts.map((shift): Schedule => {
    const dayShiftTask = shift.assigns.find((assign) => {
      return assign.order.shiftType === 1
    })
    const nightShiftTask = shift.assigns.find((assign) => {
      return assign.order.shiftType === 2
    })
    return {
      date: NewDate(shift.date).toDate(),
      color: scheduleColor(shift.schedule),
      icon: scheduleIcon(shift.schedule),
      dayShiftTask: dayShiftTask
        ? ({
            assignId: dayShiftTask.id,
            time: `${NewDate(dayShiftTask.order.contractDetail.startTime).format('HH:mm')} 〜 ${NewDate(dayShiftTask.order.contractDetail.endTime).format('HH:mm')}`,
            name: `${dayShiftTask.order.contractDetail.name} | ${dayShiftTask.order.address}`,
            color:
              dayShiftTask.status > 20
                ? 'rgb(255, 163, 128)'
                : 'rgb(255, 163, 128, 0.5)',
            icon: <span>日勤</span>,
            status: dayShiftTask.status,
          } as Task)
        : shift.schedule === 2
          ? ({
              assignId: '',
              time: '',
              name: '',
              color: 'rgb(255, 72, 0, 0.5)',
              icon: <span>日勤</span>,
              status: -1,
            } as Task)
          : undefined,
      nightShiftTask: nightShiftTask
        ? ({
            assignId: nightShiftTask.id,
            time: `${NewDate(nightShiftTask.order.contractDetail.startTime).format('HH:mm')} 〜 ${NewDate(nightShiftTask.order.contractDetail.endTime).format('HH:mm')}`,
            name: `${nightShiftTask.order.contractDetail.name} | ${nightShiftTask.order.address}`,
            color:
              nightShiftTask.status > 20
                ? 'rgb(0, 98, 140)'
                : 'rgb(0, 98, 140, 0.5)',
            icon: <span>夜勤</span>,
            status: nightShiftTask.status,
          } as Task)
        : shift.schedule === 3
          ? ({
              assignId: '',
              time: '',
              name: '',
              color: 'rgb(0, 98, 140, 0.5)',
              icon: <span>夜勤</span>,
              status: -1,
            } as Task)
          : undefined,
      dayOrNightShiftTask:
        shift.schedule === 6 && !dayShiftTask && !nightShiftTask
          ? ({
              assignId: '',
              time: '',
              name: '',
              color: 'rgb(165, 160, 207)',
              icon: <span>日or夜</span>,
              status: -1,
            } as Task)
          : undefined,
      trainingShiftTask:
        shift.schedule === 7 && !dayShiftTask && !nightShiftTask
          ? ({
              assignId: '',
              time: '',
              name: '',
              color: 'rgb(243, 161, 178)',
              icon: <span>研修</span>,
              status: -1,
            } as Task)
          : undefined,
      dayAndNightShiftTask:
        shift.schedule === 4 && !dayShiftTask && !nightShiftTask
          ? ({
              assignId: '',
              time: '',
              name: '',
              color: 'rgb(223, 173, 104)',
              icon: <span>日夜可</span>,
              status: -1,
            } as Task)
          : undefined,
      reviewShiftTask:
        shift.schedule === 5 && !dayShiftTask && !nightShiftTask
          ? ({
              assignId: '',
              time: '',
              name: '',
              color: 'rgb(137, 211, 188)',
              icon: <span>確認中</span>,
              status: -1,
            } as Task)
          : undefined,
    }
  })

  const props: CalendarProps = {
    date: currentDate ?? new Date(),
    schedules: schedules,
    onClick: (schedule) => {
      setCurrentDate(schedule?.date)
      setSchedule(schedule)
    },
    changeMonth: (date) => {
      setCurrentDate(date)
      const start = dayjs(date).startOf('month')
      const end = dayjs(date).endOf('month')

      shiftApi()
        .findByDate(start, end)
        .then((res) => {
          setShifts(res.data)
        })
    },
  }

  const listCalendarProps: ListCalendarProps = {
    currentDate: currentDate ?? new Date(),
    schedules: schedules,
    onSelectTask: (schedule: Schedule, task: Task) => {
      setSchedule(schedule)
      showModal(task.assignId)
    },
  }

  const showModal = (assignId: string) => {
    const assign = shifts
      .flatMap((shift) => shift.assigns)
      .find((assign) => assign.id === assignId)
    setAssign(assign)
    setReplyFormat(assign?.replyWhenAvailable ?? '')
    setReplyWhenUnavailable(assign?.replyWhenUnavailable ?? '')
    if (assign?.replyWhenAvailable) {
      setRes(ASSIGN_STATUS.AVAILABLE_FOR_WORK)
    } else if (assign?.replyWhenUnavailable) {
      setRes(ASSIGN_STATUS.UNAVAILABLE_FOR_WORK)
    }
    if (!assign) return
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
  }

  const handleResChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReplyWhenUnavailable('')
    setReplyWhenAvailable('')
    setRes(Number((event.target as HTMLInputElement).value))
  }

  const isDisabled = (): boolean => {
    return res === 0
  }

  const onSubmit = (notificationId: string) => {
    if (
      res === ASSIGN_STATUS.UNAVAILABLE_FOR_WORK &&
      replyWhenUnavailable.trim() === ''
    ) {
      setReplyWhenUnavailableErr('報告内容を入力してください')
      return
    }
    notificationApi()
      .replyAssignPossibility(
        notificationId,
        res,
        replyWhenAvailable,
        replyWhenUnavailable,
      )
      .then(() => {
        fetchData()
        // 報告を行った場合配置が取り消されるので、表示データを更新する必要がある
        const schedule = schedules.find(
          (schedule) => schedule.date === currentDate,
        )
        setSchedule(schedule)
        closeModal()
      })
  }

  const toMap = (order: OrderWithContractDetail) => {
    if (order.lat !== 0 && order.lng !== 0) {
      // 緯度経度が存在する場合、緯度経度でGoogleマップを開く
      location.href = `https://www.google.com/maps/search/?api=1&query=${order.lat},${order.lng}&launch_app=true`
    } else if (order.address) {
      // 緯度経度が0の場合は住所でGoogleマップを開く
      location.href = `https://www.google.com/maps/search/?api=1&query=${order.address}&launch_app=true`
    }
  }

  const handleReplyWhenUnavailable = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const data = event.target.value.trim()
    setReplyWhenUnavailable(data)
  }

  const handleReplyChange = (event: ChangeEvent<HTMLInputElement>) => {
    const data = event.target.value.trim()
    setReplyWhenAvailable(data)
  }

  return (
    <Box>
      <Box style={{ marginTop: '40px' }}>
        <TabComponent onSelectCalendar={setSelectCalendar} />
      </Box>

      {selectCalendar && (
        <Box>
          <Calendar {...props} />
          <Box style={scheduleStyle}>
            {schedule && (
              <Box>
                <h2 style={{ fontSize: '16px' }}>
                  {NewDate(schedule.date).format('YYYY年MM月DD日(ddd)')}
                </h2>
                <ul style={tasksStyle}>
                  {[schedule.dayShiftTask, schedule.nightShiftTask]
                    .filter((task) => task?.status !== -1)
                    .map((task) => {
                      if (task) {
                        return (
                          <li
                            key={task.name}
                            style={taskStyle(isFuture(schedule.date), task)}
                            onClick={() => showModal(task.assignId)}
                          >
                            <div>
                              <p
                                style={{ fontSize: '18px', fontWeight: 'bold' }}
                              >
                                {task.time}
                              </p>
                              <p style={{ fontSize: '12px' }}>{task.name}</p>
                            </div>
                            {task.icon &&
                              cloneElement(
                                task.icon as React.ReactElement<any>,
                                {
                                  style: {
                                    fontSize: '24px',
                                    color: 'rgba(255, 255, 255, 0.5)',
                                  },
                                },
                              )}
                          </li>
                        )
                      } else {
                        return null
                      }
                    })}
                </ul>
              </Box>
            )}
          </Box>
        </Box>
      )}

      {!selectCalendar && <ListCalendar {...listCalendarProps} />}
      <KumocanDialog open={open} handleClose={closeModal} maxWidth="lg">
        <Box>
          {schedule && assign && (
            <Box>
              <div style={flatRowStyle}>
                {NewDate(schedule.date).format('YYYY年MM月DD日(ddd)')}{' '}
                {NewDate(assign.order.contractDetail.startTime).format('HH:mm')}
                -{NewDate(assign.order.contractDetail.endTime).format('HH:mm')}
              </div>
              <div style={rowStyle}>
                <p style={labelStyle}>契約先名</p>
                <p style={contentStyle}>
                  {assign.order.contractDetail.contract?.name}
                </p>
              </div>
              <div style={rowStyle}>
                <p style={labelStyle}>案件名</p>
                <p style={contentStyle}>{assign.order.contractDetail.name}</p>
              </div>
              <div style={rowStyle}>
                <p style={labelStyle}>最寄駅</p>
                <p style={contentStyle}>
                  {assign.order.contractDetail.nearestStation}
                </p>
              </div>
              <div style={rowStyle}>
                <p style={labelStyle}>現場住所</p>
                <div>
                  <p style={contentStyle}>
                    {assign.order.contractDetail.address}
                  </p>
                  <div style={{ marginTop: '10px', textAlign: 'left' }}>
                    {' '}
                    <KumocanButton onClick={() => toMap(assign.order)}>
                      地図を表示
                    </KumocanButton>
                  </div>
                </div>
              </div>
              {assign.members && (
                <div style={rowStyle}>
                  <p style={labelStyle}>動務者</p>
                  <div style={multiContentStyle}>
                    {assign.members.map((member) => {
                      return (
                        <span key={member.security.id} style={tagStyle}>
                          {member.security.lastName} {member.security.firstName}
                        </span>
                      )
                    })}
                  </div>
                </div>
              )}
              {assign.status === 20 &&
                assign.notification &&
                assign.notification?.id !== '' && (
                  <Box>
                    <div style={flatRowStyle}>
                      <p
                        style={{
                          ...labelStyle,
                          width: '100%',
                          paddingBottom: '8px',
                        }}
                      >
                        管制室からのコメント
                      </p>
                      <p>{assign.notification.content}</p>
                    </div>

                    <FormControl style={{ width: '100%' }}>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={res}
                        onChange={handleResChange}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          gap: '20px',
                          marginBottom: '10px',
                        }}
                      >
                        <FormControlLabel
                          value={ASSIGN_STATUS.AVAILABLE_FOR_WORK}
                          control={
                            <Radio
                              sx={{
                                color: '#cccccc',
                                '&.Mui-checked': {
                                  color: '#14b7ae',
                                },
                              }}
                            />
                          }
                          label="確認しました"
                          sx={{ fontSize: '16px' }}
                        />

                        <FormControlLabel
                          value={ASSIGN_STATUS.UNAVAILABLE_FOR_WORK}
                          control={
                            <Radio
                              sx={{
                                color: '#cccccc',
                                '&.Mui-checked': {
                                  color: '#14b7ae',
                                },
                              }}
                            />
                          }
                          label="報告"
                          sx={{ fontSize: '16px' }}
                        />
                      </RadioGroup>

                      {res === ASSIGN_STATUS.UNAVAILABLE_FOR_WORK && (
                        <TextField
                          sx={{ marginTop: '8px', marginBottom: '8px' }}
                          id="outlined-multiline-flexible"
                          label="報告内容"
                          multiline
                          maxRows={5}
                          required
                          onChange={handleReplyWhenUnavailable}
                          error={!!replyWhenUnavailableErr.trim()}
                        />
                      )}

                      {res === ASSIGN_STATUS.AVAILABLE_FOR_WORK && (
                        <TextField
                          label="返信コメント"
                          sx={{ marginTop: '8px', marginBottom: '8px' }}
                          id="outlined-multiline-flexible"
                          multiline
                          defaultValue={replyFormat}
                          maxRows={5}
                          onChange={handleReplyChange}
                        />
                      )}

                      <KumocanButton
                        onClick={() => onSubmit(assign.notification!.id)}
                        style={submitButtonStyle}
                        disabled={isDisabled()}
                      >
                        回答する
                      </KumocanButton>
                    </FormControl>
                  </Box>
                )}
            </Box>
          )}
        </Box>
      </KumocanDialog>
    </Box>
  )
}
