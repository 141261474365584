import { TextareaAutosize, styled } from '@mui/material'
import { CSSProperties } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'

interface Props {
  id: string
  register?: UseFormRegisterReturn
  required?: boolean
  disabled?: boolean
  style?: CSSProperties
  placeholder?: string
  onInput?: (v: string) => void
  onFocus?: () => void
}

const StyledTextareaAutosize = styled(TextareaAutosize)(() => ({
  '& .Mui-disabled': {
    WebkitTextFillColor: 'black',
  },
  '& .MuiInputBase-input': {
    WebkitTextFillColor: 'black',
  },
}))

export default function KumocanTextareaAutosize(props: Props) {
  const {
    id,
    register,
    required = false,
    disabled = false,
    style: style = {},
    placeholder = '',
    onInput,
    onFocus,
  } = props

  const handleInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onInput) {
      onInput(event.target.value)
    }
  }

  return (
    <StyledTextareaAutosize
      placeholder={placeholder}
      id={id}
      {...register}
      required={required}
      disabled={disabled}
      style={style}
      onInput={handleInput}
      onFocus={onFocus}
      autoComplete="off"
    />
  )
}
